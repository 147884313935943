export const PracticeAreaLandingData = {
    title: 'Injured? We can help.',
    staticIntroduction: `<p>Have you been injured at work, on the road or through negligence of another?</p>
    <p>You may be entitled to compensation.</p>`,
    hasImage: true,
    heroImage: {
        mobileImage: 'https://source.unsplash.com/featured/767x664?people',
        tabletImage: 'https://source.unsplash.com/featured/1023x800?people',
        desktopImage: 'https://source.unsplash.com/featured/1600x800?people',
    },
    cta1: {
        txt: ``,
        url: ``
    },
    cta2: {
        txt: '',
        url: ''
    },
    mediaQuery: '',
    showContact: '',
    breadcrumbs: [
        {
            title: 'Home'
        }, {
            title: `I've Been Injured`,
            isActive: true
        }
    ],
    subPracticeAreasTiles: [
        {
            icon: 'in-the-workplace',
            title: 'In the workplace',
            pageUrl: '#',
            blurb: `<p>If you or a family member is injured at work, it is important to get the right advice about a Workers Compensation claim.</p>`,
            tileLayout: "sideways_layout"
        },
        {
            icon: 'dust-exposure',
            title: 'From dust exposure',
            pageUrl: '#',
            blurb: `<p>If you or a family member has been diagnosed with a disease from workplace exposure to asbestos or silica dust, it is important to get the right advice about compensation.</p>`,
            tileLayout: "sideways_layout"
        },
        {
            icon: 'on-the-road',
            title: 'On the road',
            pageUrl: '#',
            blurb: `<p>If you or a family member is injured on the road in a motor vehicle, public transport, or cycling accident it is important to get the right advice about a TAC claim.</p>`,
            tileLayout: "sideways_layout"
        },
        {
            icon: 'medical-professional',
            title: 'By a medical profressional',
            pageUrl: '#',
            blurb: `<p>If you or a family member have become injured or unwell as a result of treatment by a medical practitioner, it is important to get the right advice.</p>`,
            tileLayout: "sideways_layout"
        },
        {
            icon: 'in-a-public-place',
            title: 'In a public place',
            pageUrl: '#',
            blurb: `<p>If you or a family member is injured in a "slip and fall" in a public place, it is important to get the right advice.</p>`,
            tileLayout: "sideways_layout"
        },
    ],
    featuresTiles: [
        {
            icon: 'dust-disease',
            title: 'Expert Advice',
            blurb: `<p>Adviceline Injury Lawyers has been assisting injured Victorians for more than 40 years.</p>`
        },
        {
            icon: 'contact',
            title: 'We Care',
            blurb: `<p>Our unique and personal approach to accessing entitlements and lump sum compensation allows you to take a backseat and focus on what is most important to you.</p>`
        },
        {
            icon: 'workplace-injury',
            title: 'No Win, No Fee',
            blurb: `<p>We offer a No Win, No Fee arrangement, meaning that if we proceed with a claim you will only have to pay legal costs if we are successful in getting you compensation.</p>`
        },
    ]
};




