import React from 'react';
import StaticLayout from '~layouts/static-layout';
import Header from '~2-components/Header/Header';
import { RenderedContext } from '~layouts/layout';
import { PracticeAreaLandingData } from '~data/practiceAreaLandingData';
import PageSection from '~2-components/PageSection/PageSection';
import TileContainer from '~2-components/TileContainer/TileContainer';
import ContentIconTile from '~2-components/ContentIconTile/ContentIconTile';

const PracticeAreaLanding = () => (
    <StaticLayout>
        <RenderedContext.Provider value={PracticeAreaLandingData}>
            <Header />
        </RenderedContext.Provider>

        <div className="page-content">
            <div className="container">
                <PageSection
                    backgroundTheme="grey"
                >
                    <h4>If you are injured or unwell and believe that you may be entitled to compensation, it is important to get advice early.</h4>

                    <TileContainer
                        columns="single_column"
                    >
                        {PracticeAreaLandingData.subPracticeAreasTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                    tileLayout={tile.tileLayout}
                                />
                            );
                        })}
                    </TileContainer>
                </PageSection>

                <PageSection>
                    <h2>Why work with us?</h2>

                    <TileContainer>
                        {PracticeAreaLandingData.featuresTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                />
                            );
                        })}
                    </TileContainer>
                </PageSection>
            </div>
        </div>
    </StaticLayout>
);

export default PracticeAreaLanding;
